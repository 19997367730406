import { ProductCategoryRepository } from "../repository";
import { PostData } from "@/utils";

const ProductCategoryController = {

    /**
    * @return {Object} request data
    */
    async getProductCategories() {
        const res = await ProductCategoryRepository.getProductCategories();
        return res?.data?.categories || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getProductCategory(uid: number) {
        const res = await ProductCategoryRepository.getProductCategory(uid);
        return res?.data?.productcategory || res;
    },    

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ProductCategoryRepository.create(postData)
        return response?.data?.productcategory || response;
    }, 

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ProductCategoryRepository.update(postData)
        return response?.data?.productcategory || response;
    },      

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await ProductCategoryRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },  
}

export default ProductCategoryController;