import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["data-value", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-options", _ctx.alignment]),
    id: _ctx.cId
  }, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, idx) => {
      return (_openBlock(), _createElementBlock("p", {
        key: _ctx.cId + '_' +idx,
        class: _normalizeClass(["ww-custom-option", {'is-checked': _ctx.value == option.value}]),
        "data-value": option.value,
        onClick: ($event: any) => (_ctx.handleChange(option))
      }, _toDisplayString(option.label), 11, _hoisted_2))
    }), 128))
  ], 10, _hoisted_1))
}