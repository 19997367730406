import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './permission.ts'
import i18n from './i18n'
import "./assets/scss/app.scss"
import { wwNotify, wwConfirm } from "./plugins"

const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(router);
app.use(wwNotify, { dismissErrors: true });
app.use(wwConfirm, {});
app.mount('#app');
