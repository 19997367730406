import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const TicketingRepository = {
    
     /**
     * getTicketings
     * 
     * @returns {Object} server response
     */ 
    async getTicketings() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_TICKETINGS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('TicketingRepository: getTicketings Response', res)
        return res
    }, 
   
     /**
     * getBooking
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getTicketing(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_TICKETING + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('BookingRepository: getTicketing Response', res)
        return res
    },

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_TICKETING, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createTicketing', res)
        return res;
    },   

     /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    /* async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_BOOKING, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateBooking', res)
        return res;
    },  */

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    /* async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_BOOKING, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteBooking', res)
        return res;
    },  */            
}

export default TicketingRepository;