
import { defineComponent } from 'vue'
import { Unique } from '@/utils';

export default defineComponent({
   name: 'UiNumberSwitch',
   props: { 
        label: {
            type: String,
            default: ""
        },
        max: {
            type: Number,
            default: null
        },   
        available: {
            type: Number,
            default: 0
        },
        value: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: 'option'
        }, 
        alignment: {
            type: String,
            default: 'vertical'
        },
        size: {
            type: String,
            default: 'large'
        }
   },
   data() {
        return {
            modelValue: 0 as any,
            cId: Unique.getUnique(),
            icons: {
                check: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M16.5 4.40002L6.9 14L3.8 10.9" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            }
        }
   },
   computed: {
    alignmentAndSize() {
        return this.alignment + ' ' + this.size
    }   
   },
   watch: {
        value() {
            this.modelValue = this.value
        },
        available() {
            if (this.modelValue > this.available) this.modelValue = this.available
        }
   },
   mounted() {
        this.modelValue = this.value;
   },
   methods: {
       handleChange(value: any) {
           this.modelValue = value
           this.$emit('onChange', this.modelValue)
       },       
   }
})
