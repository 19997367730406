import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ww-colorpicker" }
const _hoisted_2 = { class: "color-picker-icon-trigger" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.name
          }, _toDisplayString(_ctx.label), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        id: _ctx.name,
        type: "color",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newColor) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.styleChange && _ctx.styleChange(...args)))
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.newColor]
      ])
    ])
  ]))
}