import { VoucherRepository } from "../repository";
import { PostData } from "@/utils";
import { isProxy, toRaw } from 'vue';

const VoucherController = {

    /**
    * @return {Object} request data
    */
    async getVouchers() {
        const res = await VoucherRepository.getVouchers();
        return res?.data?.vouchers || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getVoucher(uid: number) {
        const res = await VoucherRepository.getVoucher(uid);
        return res?.data?.voucher || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        /* if (isProxy(data)) {
            console.log('isProxy', data, toRaw(data))
            data = toRaw(data)
        } */
        const postData = JSON.stringify(data)
        const response : any = await VoucherRepository.create(postData)
        return response?.data?.voucher || response?.error;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = JSON.stringify(data)
        const response : any = await VoucherRepository.update(postData)
        return response?.data?.voucher || response?.error;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const postData = JSON.stringify({ uid: uid })
        const response : any = await VoucherRepository.delete(postData) //VoucherRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },

     /**
     * import
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async import(file : any){
        const formData = new FormData()
        formData.append('file', file)
        
        const response : any = await VoucherRepository.import(formData)
        return response
    },

    /**
    * @param {Object} code
    * @return {Object} request data
    */
    async check(formData: any) {
        const postData = `voucherCode=${formData.voucherCode}`
        const response : any = await VoucherRepository.check(postData)
        return response?.data?.result || response;
    },

    /**
    * synchronize
    * @return {Object} request data
    */
    async synchronize() {
        const res : any = await VoucherRepository.synchronize();
        return res?.data?.vouchers || [];
    }     
}

export default VoucherController;