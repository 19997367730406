
import { defineComponent } from 'vue';

export default defineComponent({
  name: "UiSwitch",
  props: {
      handles: {
        type: Array,
        default: null
      },
      name: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: ''
      },
      value: {
        type: Boolean,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      mini: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      modelValue: this.value
    }
  },
  watch: {
    value(){
      this.modelValue = this.value
    }
  },
  methods: {
    onChange() {
      if (!this.disabled) {
        this.modelValue = !this.value;
        this.$emit("onChange", this.modelValue);
      }
    },
    onTransitionend(ev : Event) {
        //TODO: check if works in every case
      this.$emit("onChange", this.modelValue);
    }
  },
});
