const DateTimeUtils =  {


    getFirstDayOfWeek(d: Date) {
        d = new Date(d)
        const day = d.getDay()
        const diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    getLastDayOfWeek(d: Date) {
        d = new Date(d)
        const day = d.getDay()
        const diff = d.getDate() - day + (day == 0 ? -6:1) + 6; // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    treatAsUTC(date: Date): any {
        const result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    },

    daysBetween(startDate: Date, endDate: Date) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay +1;
    },

    addDays(date: Date, days: number): Date {
        const d = new Date(date.valueOf());
        d.setDate(d.getDate() + days);
        return d;
    },

    getDatesFromTo(from: Date, to: Date) {
        from.setHours(0,0,0,0)
        const days = this.daysBetween(from, to)
        const dates = []
        for(let i=0; i < days; i++) {
            const d = DateTimeUtils.addDays(from, i)
            dates.push({
                'day': d.getDate(),
                'weekday': d.getDay(),
                'fullDate': d,
                'date': this.formatDate(d),
                'timestamp': + d,
                'dateNumber': this.getDateNumber(d),
                'index': i
            })
        }
        return dates    
    },

    getTimesFromTo(from: number, to: number) {
        const times = [];
        const amount = to - from
        for(let i=0; i<=amount; i++) {
            const c = from + i
            times.push({
                index: i,
                time: c,
                label: (c < 10 ? '0' + c : c) + ':00',
                timestamp: c*60*60
            })
        }
        return times
    },

    formatDate(d: Date): string {
        return (d.getDate()<10 ? '0': '') + d.getDate() + '.' + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + '.' + d.getFullYear()
    },

    formatDateForInput(d: Date): string {
        return d.getFullYear() + '-' + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + '-' + (d.getDate()<10 ? '0': '') + d.getDate()
    },

    getDateNumber(d: Date): number {
        return  parseInt((d.getFullYear() + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + (d.getDate()<10 ? '0': '') + d.getDate()))
    },

    convertSecondsToTime(seconds:number) {
        let hours : any = Math.floor(seconds / 3600);
        let minutes : any = Math.floor((seconds - (hours * 3600)) / 60);
        let timestring = "";
        if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        return timestring;
    },

    convertTimeStampToDate(tstamp:number) {
        const date = new Date(tstamp * 1000);
        let datestring = "";
        if (date.getDay() < 10) { datestring += "0" + date.getDay() + "."; } else { datestring+= date.getDay() + "."}
        if (date.getDate() < 10) { datestring += "0" + date.getDate() + "."; } else { datestring+= date.getDate() + "."}
        if (date.getFullYear() < 10) { datestring += "0" + date.getFullYear(); } else { datestring+= date.getFullYear()}
        return datestring;
    },

    convertTimeStampToTime(tstamp:number){
        const hours = Math.floor(tstamp / 3600)
        const minutes = Math.floor((tstamp / 60) % 60);
        
        return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    },

    convertToDate(datetime:string) {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
            }
            const date = new Date(datetime)
            const m = date.getMonth()+1
            return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return '-'
        }
    },

    convertLinuxDateStringToDate(datetime:string): Date | null {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
                const d = new Date(datetime)
                d.setHours(0,0,0,0)
                return d
            } else {
                return null
            }
        } else {
            return null
        }        
        //const m = date.getMonth()+1
        //return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        
    },

    convertLinuxDateStringToTimestamp(datetime:string): number {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
                return + new Date(datetime)
            } else {
                return 0
            }
            //const m = date.getMonth()+1
            //return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return 0
        }
    },

    /* extractDateOnly(datetime:string) {
        return datetime.split('T')[0]
    } */

};
export default DateTimeUtils;