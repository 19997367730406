import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const SeasonRepository = {
    
    /**
     * getSeasons
     * 
     * @returns {Object} server response
     */ 
    async getSeasons() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_SEASONS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('SeasonRepository: getSeasons Response', res)
        return res
    },

    /**
     * getSeason
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getSeason(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_SEASON + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('SeasonRepository: getSeason Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_SEASON, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createSeason', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_SEASON, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateSeason', res)
        return res;
    },

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_SEASON, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteSeason', res)
        return res;
    }
   
}

export default SeasonRepository;