import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const GuideRepository = {
    
    /**
     * getGuides
     * 
     * @returns {Object} server response
     */ 
    async getGuides() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_GUIDES).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('GuideRepository: getGuides Response', res)
        return res
    },

    /**
     * getGuide
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getGuide(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_GUIDE + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('GuideRepository: getGuide Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.CREATE_GUIDE, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('createGuide', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.UPDATE_GUIDE, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('updateGuide', res)
        return res;
    },

     /**
     * delete
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async delete(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.DELETE_GUIDE, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('deleteGuide', res)
        return res;
    }
   
}

export default GuideRepository;