import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ww-table" }
const _hoisted_2 = {
  key: 0,
  class: "ww-table-header"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["title", "onClick", "onMouseover"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "td-actions"
}
const _hoisted_14 = {
  key: 0,
  class: "empty-state-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIconButton = _resolveComponent("UiIconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.icon
          }, null, 8, _hoisted_3),
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _createElementVNode("thead", {
        class: _normalizeClass({'head-rounded': !_ctx.title})
      }, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
            return (_openBlock(), _createElementBlock("th", {
              class: _normalizeClass({ 'sorted': _ctx.currentSortField.property == field.property}),
              key: field,
              title: (_ctx.sortedAsc ? 'Ascending':'Descending') + ' sorted by '  + _ctx.currentSortField.property,
              onClick: ($event: any) => (_ctx.handleSortBy(field)),
              onMouseover: ($event: any) => (_ctx.startHover(field)),
              onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.endHover()))
            }, [
              _createTextVNode(_toDisplayString(field.title) + " ", 1),
              (_ctx.currentSortField == field && _ctx.sortedAsc || _ctx.hover == field && _ctx.currentSortField != field)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createElementVNode("i", {
                      class: "svgicon active",
                      innerHTML: _ctx.icons.arrowsort
                    }, null, 8, _hoisted_6)
                  ]))
                : (_ctx.currentSortField == field && !_ctx.sortedAsc)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createElementVNode("i", {
                        class: "svgicon active",
                        style: {"transform":"rotate(180deg)"},
                        innerHTML: _ctx.icons.arrowsort
                      }, null, 8, _hoisted_8)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createElementVNode("i", {
                        class: "svgicon",
                        innerHTML: _ctx.icons.arrowsort
                      }, null, 8, _hoisted_10)
                    ]))
            ], 42, _hoisted_4))
          }), 128)),
          (_ctx.actions)
            ? (_openBlock(), _createElementBlock("th", _hoisted_11))
            : _createCommentVNode("", true)
        ])
      ], 2),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedList, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.uid,
            class: _normalizeClass({ 'is--selected' : _ctx.isItemSelected(item) })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index,
                onClick: ($event: any) => (_ctx.handleActionClicked(field.clickAction, item))
              }, [
                (field.type == 'boolean')
                  ? (_openBlock(), _createBlock(_component_UiIconButton, {
                      key: 0,
                      class: _normalizeClass(["ww-button-toggle", { 'active': _ctx.checkTBField(index, item, field) == true }]),
                      icon: _ctx.icons.toggle,
                      onOnClick: ($event: any) => (_ctx.handleActionClicked(_ctx.actions.name, item))
                    }, null, 8, ["class", "icon", "onOnClick"]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass({'clickable': field.clickAction})
                    }, _toDisplayString(_ctx.checkTBField(index, item, field)), 3))
              ], 8, _hoisted_12))
            }), 128)),
            (_ctx.actions)
              ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                    return (_openBlock(), _createBlock(_component_UiIconButton, {
                      key: action.name,
                      icon: action.icon,
                      onOnClick: ($event: any) => (_ctx.handleActionClicked(action.name, item))
                    }, null, 8, ["icon", "onOnClick"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        (_ctx.src.length == 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
              _createElementVNode("td", null, _toDisplayString(_ctx.emptyStateText ? _ctx.emptyStateText : _ctx.$t('emptyState.default')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}