import { GuideRepository } from "../repository";
import { PostData } from "@/utils";
import { isProxy, toRaw } from 'vue';

const GuideController = {

    /**
    * @return {Object} request data
    */
    async getGuides() {
        const res = await GuideRepository.getGuides();
        return res?.data?.guides || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getGuide(uid: number) {
        const res = await GuideRepository.getGuide(uid);
        return res?.data?.guide || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        /* if (isProxy(data)) {
            console.log('isProxy', data, toRaw(data))
            data = toRaw(data)
        } */
        const postData = JSON.stringify(data)
        const response : any = await GuideRepository.create(postData)
        return response?.data?.guide || response?.error;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = JSON.stringify(data)
        const response : any = await GuideRepository.update(postData)
        return response?.data?.guide || response?.error;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const postData = JSON.stringify({ uid: uid })
        const response : any = await GuideRepository.delete(postData) //GuideRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },
}

export default GuideController;