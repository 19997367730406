import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const BookingRepository = {
    
     /**
     * getBookings
     * 
     * @returns {Object} server response
     */ 
    async getBookings() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_BOOKINGS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('BookingRepository: getBookings Response', res)
        return res
    },
   
     /**
     * getBooking
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getBooking(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_BOOKING + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('BookingRepository: getBooking Response', res)
        return res
    },   

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_BOOKING, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createBooking', res)
        return res;
    },   

     /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_BOOKING, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateBooking', res)
        return res;
    }, 

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_BOOKING, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteBooking', res)
        return res;
    },             
}

export default BookingRepository;