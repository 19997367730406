import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    redirect: "/",
    name: "Home",    
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        name: "home",
        meta: {
          title: "anybooka Home",
          noCache: true,
          noSidebar: true
        }
      }
    ]
  },
  {
    path: "/payment",
    redirect: "/",
    name: "Payment",    
    children: [
      {
        path: "/payment/:id",
        component: () => import("@/views/payment/index.vue"),
        name: "payment",
        meta: {
          title: "anybooka Payment",
          noCache: true,
          noSidebar: true
        }
      }
    ]
  },
  {
    path: "/ticketingsuccess/:id",
    redirect: "/",
    name: "TicketingSuccess",
    children: [
      {
        path: "/ticketingsuccess/:id",
        component: () => import("@/views/ticketingsuccess/index.vue"),
        name: "ticketingsuccess",
        meta: {
          title: "Ticketing success",
          noCache: true,
          noSidebar: true
        }
      }
    ]
  },
  {
    path: "/login",
    redirect: "/",
    name: "Login",
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index.vue"),
        name: "login",
        meta: {
          title: "anybooka Login",
          noCache: true,
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },  
  {
    path: "/register",
    redirect: "/",
    name: "Register",
    children: [
      {
        path: "/register/:id?",
        component: () => import("@/views/register/index.vue"),
        name: "register",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }           
      }
    ]
  },  
  {
    path: "/confirmemail",
    redirect: "/",
    name: "ConfirmEmail",
    children: [
      {
        path: "/confirmemail/:id",
        component: () => import("@/views/confirmemail/index.vue"),
        name: "confirmemail",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },      
  {
    path: "/forgotpassword",
    redirect: "/",
    name: "ForgotPassword",
    children: [
      {
        path: "/forgotpassword",
        component: () => import("@/views/forgotpassword/index.vue"),
        name: "forgotpassword",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },  
  {
    path: "/resetpassword",
    redirect: "/",
    name: "ResetPassword",
    children: [
      {
        path: "/resetpassword/:hash",
        component: () => import("@/views/resetpassword/index.vue"),
        name: "resetpassword",
        meta: {
          noLoginCheck: true,
          noSidebar: true
        }
      }
    ]
  },    
  {
    path: "/admin/dashboard",
    redirect: "/",
    name: "Dashboard",
    children: [
      {
        path: "/admin/dashboard",
        component: () => import("@/views/admin/dashboard/index.vue"),
        name: "dashboard",
        meta: {
          noLoginCheck: false,
          rights: 'admin'
        }
      }
    ]
  },   
  {
    path: "/admin/catalog/products",
    redirect: "/",
    name: "Products",
    children: [
      {
        path: "/admin/catalog/products",
        component: () => import("@/views/admin/catalog/products/index.vue"),
        name: "products",
        meta: {
          noLoginCheck: false,
          menu: 'products',
          parentMenu: 'catalog',
          rights: 'admin'
        }
      },
      {
        path: "/admin/catalog/products/form/:id",
        component: () => import("@/views/admin/catalog/products/form.vue"),
        name: "productform",
        meta: {
          noLoginCheck: false,
          menu: 'products',
          parentMenu: 'catalog',
          hasBackButton: true,
          rights: 'admin'
        }
      },
      {
        path: "/admin/catalog/items",
        component: () => import("@/views/admin/catalog/items/index.vue"),
        name: "items",
        meta: {
          noLoginCheck: false,
          menu: 'items',
          parentMenu: 'catalog',
          rights: 'admin'
        }
      }, {
        path: "/admin/catalog/items/form/:id",
        component: () => import("@/views/admin/catalog/items/form.vue"),
        name: "itemform",
        meta: {
          noLoginCheck: false,
          menu: 'items',
          parentMenu: 'catalog',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/catalog/productcategories",
        component: () => import("@/views/admin/catalog/productcategories/index.vue"),
        name: "productcategories",
        meta: {
          noLoginCheck: false,
          menu: 'productcategories',
          parentMenu: 'catalog',
          rights: 'admin'
        }
      }, {
        path: "/admin/catalog/productcategories/form/:id",
        component: () => import("@/views/admin/catalog/productcategories/form.vue"),
        name: "productcategoryform",
        meta: {
          noLoginCheck: false,
          menu: 'productcategories',
          parentMenu: 'catalog',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/catalog/vouchers",
        component: () => import("@/views/admin/catalog/vouchers/index.vue"),
        name: "vouchers",
        meta: {
          noLoginCheck: false,
          menu: 'vouchers',
          parentMenu: 'catalog',
          rights: 'admin'
        }
      }, {
        path: "/admin/catalog/voucher/import",
        component: () => import("@/views/admin/catalog/vouchers/import.vue"),
        name: "vouchersimport",
        meta: {
          noLoginCheck: false,
          menu: 'vouchersimport',
          parentMenu: 'catalog',
          hasBackButton: true,
          rights: 'admin'
        }
      },      
    ]
  },
  {
    path: "/admin/booking",
    name: "Booking",
    children: [
      {
        path: "/admin/booking",
        component: () => import("@/views/admin/booking/index.vue"),
        name: "booking",
        meta: {
          noLoginCheck: false,
          menu: 'booking',
          hasBackButton: false,
          rights: 'admin'
        }
      },
      {
        path: "/admin/booking/form",
        component: () => import("@/views/admin/booking/form.vue"),
        name: "bookingform",
        meta: {
          noLoginCheck: false,
          menu: 'bookingform',
          hasBackButton: true,
          rights: 'admin'
        }
      }      
    ]
  },  
  {
    path: "/admin/partner",
    redirect: "/",
    name: "Partner",
    children: [
      {
        path: "/admin/partner",
        component: () => import("@/views/admin/partner/index.vue"),
        name: "partner",
        meta: {
          noLoginCheck: false,
          menu: 'partner',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/partner/form/:id",
        component: () => import("@/views/admin/partner/form.vue"),
        name: "partnerform",
        meta: {
          noLoginCheck: false,
          menu: 'partner',
          parentMenu: 'partner',
          hasBackButton: true,
          rights: 'admin'
        }
      }     
    ]
  }, {
    path: "/admin/company/locations",
    redirect: "/",
    name: "Locations",
    children: [
      {
        path: "/admin/company/locations",
        component: () => import("@/views/admin/company/locations/index.vue"),
        name: "locations",
        meta: {
          noLoginCheck: false,
          menu: 'locations',
          parentMenu: 'company',
          rights: 'admin'
        }
      }, {
        path: "/admin/company/locations/form/:id",
        component: () => import("@/views/admin/company/locations/form.vue"),
        name: "locationform",
        meta: {
          noLoginCheck: false,
          menu: 'locations',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/company/location/:locationId/openinghours/",
        component: () => import("@/views/admin/company/openinghours/index.vue"),
        name: "locationopeninghours",
        meta: {
          noLoginCheck: false,
          menu: 'locations',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }, 
    ]
  }, {
    path: "/admin/company/seasons",
    redirect: "/",
    name: "Seasons",
    children: [
      {      
        path: "/admin/company/seasons",
        component: () => import("@/views/admin/company/seasons/index.vue"),
        name: "seasons",
        meta: {
          noLoginCheck: false,
          menu: 'seasons',
          parentMenu: 'company',
          rights: 'admin'
        }
      }, {
        path: "/admin/company/seasons/form/:id",
        component: () => import("@/views/admin/company/seasons/form.vue"),
        name: "seasonform",
        meta: {
          noLoginCheck: false,
          menu: 'seasons',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/company/season/:seasonId/openinghours/",
        component: () => import("@/views/admin/company/openinghours/index.vue"),
        name: "seasonopeninghours",
        meta: {
          noLoginCheck: false,
          menu: 'seasons',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }, {
        path: "/admin/company/season/:seasonId/:locationId/openinghours/",
        component: () => import("@/views/admin/company/openinghours/index.vue"),
        name: "seasonopeninghours",
        meta: {
          noLoginCheck: false,
          menu: 'seasons',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }
    ] 
  }, {
    path: "/admin/company/openinghours",
    redirect: "/",
    name: "Openinghours",    
    children: [
      {
        path: "/admin/company/openinghours",
        component: () => import("@/views/admin/company/openinghours/index.vue"),
        name: "openinghours",
        meta: {
          noLoginCheck: false,
          menu: 'openinghours',
          parentMenu: 'company',
          rights: 'admin'
        }
      }, {
        path: "/admin/company/openinghours/form/:id",
        component: () => import("@/views/admin/company/openinghours/form.vue"),
        name: "openinghourform",
        meta: {
          noLoginCheck: false,
          menu: 'openinghours',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }
    ]
  }, {
    path: "/admin/company/guides",
    redirect: "/",
    name: "Guides",
    children: [
      {
        path: "/admin/company/guides",
        component: () => import("@/views/admin/company/guides/index.vue"),
        name: "guides",
        meta: {
          noLoginCheck: false,
          menu: 'guides',
          parentMenu: 'company',
          rights: 'admin'
        }
      }, {
        path: "/admin/company/guides/form/:id",
        component: () => import("@/views/admin/company/guides/form.vue"),
        name: "guideform",
        meta: {
          noLoginCheck: false,
          menu: 'guides',
          parentMenu: 'company',
          hasBackButton: true,
          rights: 'admin'
        }
      }   
    ]
  }, {
    path: "/partner",
    redirect: "/",
    name: "PartnerBookings",    
    children: [
      {
        path: "/partner",
        component: () => import("@/views/partner/booking/index.vue"),
        name: "partnerbookings",
        meta: {
          noLoginCheck: false,
          menu: 'guides',
          parentMenu: 'company',
          noSidebar: true,
          rights: 'partner'
        }
      },
      {
        path: "/partner/bookings/form",
        component: () => import("@/views/partner/booking/form.vue"),
        name: "partnerbookingform",
        meta: {
          noLoginCheck: false,
          title: "Parnter Booking",
          noCache: true,
          noSidebar: true,
          hasBackButton: true,
          rights: 'partner'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
