import { ProductRepository } from "../repository";
import { PostData } from "@/utils";

const ProductController = {

    /**
    * @return {Object} request data
    */
    async getProducts() {
        const res = await ProductRepository.getProducts();
        return res?.data?.products || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getProduct(uid: number) {
        const res = await ProductRepository.getProduct(uid);
        return res?.data?.product || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = PostData.buildFormData(data, null, '')
        console.log('data / postData', data,  postData)
        const response : any = await ProductRepository.create(postData)
        return response?.data?.product || response;
    }, 

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.buildFormData(data, null, '')
        //const postData = JSON.stringify(data)
        console.log('data / postData', data,  postData)
        const response : any = await ProductRepository.update(postData)
        return response?.data?.product || response;
    },        

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await ProductRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },  

    /**
    * @param {Array} products
    * @param {Array} availabilieties
    * @return {void}
    */
    assignProductAvailabilities(products: Array<any>, availabilities: Array<any>) {
        products.forEach((product:any) => {
            product.availabilities = availabilities.filter((a:any) => a.product.uid == product.uid)
        });
    },

    /**
    * @param {Object} product
    * @return {number} amount available in every bookable
    */
    getProductMaxAvailabilityPerBookable(product: any): number {
        if (product?.items) {
            const available = Object.keys(product.items).length
            const bookingTime = product.settings.setupTime + product.settings.bookingTime
            if (bookingTime != 0 && bookingTime != product.settings.bookableEvery) {
                return available * Math.floor(product.settings.bookableEvery / bookingTime)
            } else {
                return available
            }
        } else {
            return 0
        }
    }    
}
export default ProductController;