import { UserRepository } from "../repository";
import router from "@/router";
import { useEventBus } from '@vueuse/core'
import { appEventKey } from '@/bus/eventkey'
import { UserRightsService } from '@/service'

const UserController = {
    user: null as any,
    router,
    eventBus: useEventBus(appEventKey),

    /**
    * @return {Object} requested userdata
    */
    async getUser() {
        const response: any = await UserRepository.getUser();
        if(response?.data?.user) {
            this.user = response.data.user
            this.eventBus.emit('onUser')
            UserRightsService.setUserRights()
            return this.user;
        } else {
            this.router.push('/login')
        }
        //return response.data.user
    },
    
    /**
    * @param {username} string
    * @return {Object} request data
    */
    async sendPasswordLink(username: string) {
       const postData = `username=${username}`
       return await UserRepository.forgotPassword(postData);
    },

    /**
    * @param {postData} any
    * @return {Object} request data
    */
    async resetPassword(postData: any){
        //postData = DataService.buildPostData(postData,'', true);
        postData = JSON.stringify(postData)
        const response : any = await UserRepository.resetPassword(postData)
        return response
    },

    /**
    * @param {userdata} any
    * @return {Object} request data
    */
    async create(userdata: any) {
        userdata = JSON.stringify(userdata)
        const response : any = await UserRepository.create(userdata)
        return response;
    },

    /**
    * @param {postData} any
    * @return {Object} request data
    */
    async confirmMail(postData: any) {
        postData = JSON.stringify(postData)
        const response : any = await UserRepository.confirmMail(postData)
        return response;
    } 

}
export default UserController;