import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const VoucherRepository = {
    
    /**
     * getVouchers
     * 
     * @returns {Object} server response
     */ 
    async getVouchers() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_VOUCHERS).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('VoucherRepository: getVouchers Response', res)
        return res
    },

    /**
     * getVoucher
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getVoucher(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_VOUCHER + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('VoucherRepository: getVoucher Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.CREATE_VOUCHER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('createVoucher', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.UPDATE_VOUCHER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('updateVoucher', res)
        return res;
    },

    /**
     * delete
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async delete(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.DELETE_VOUCHER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('deleteVoucher', res)
        return res;
    },

    /**
     * import
     * 
     * @param {Object} postData
     * 
     * @returns {Object} server response
     */  
    async import(postData: any) {
        console.log('ImportRepository:', postData);
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.IMPORT_VOUCHERS, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        return res
    },

     /**
     * check
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async check(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.CHECK_VOUCHER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('checkVoucher', res)
        return res;
    },    

     /**
     * synchronize
     * 
     * @returns {Object} server response
     */ 
    async synchronize() {
        const res = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.SYNC_VOUCHERS).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('synhronize', res)
        return res;
    },   

}

export default VoucherRepository;