import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "data-buttons"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-value", "onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-number-switch", _ctx.alignmentAndSize]),
    id: _ctx.cId,
    "data-buttons": _ctx.max
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available, (idx) => {
      return (_openBlock(), _createElementBlock("button", {
        key: _ctx.cId + '_' +idx,
        class: _normalizeClass(["ww-number-switch-button", {'is-selected': idx <= _ctx.modelValue, 'available-few': (this.available < (this.max / this.available)), 'available-many': (this.available >= (this.max / this.available))}]),
        "data-value": idx,
        onClick: _withModifiers(($event: any) => (_ctx.handleChange(idx)), ["prevent"]),
        disabled: (idx > _ctx.available)
      }, _toDisplayString(idx), 11, _hoisted_3))
    }), 128))
  ], 10, _hoisted_1))
}