import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const CompanyRepository = {
    
    /**
     * getCompany
     * 
     * @returns {Object} server response
     */ 
    async getCompany() {
        const res: any = await axios.create({ withCredentials: false }).get(Api.BACKEND_URL + Api.GET_COMPANY).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('CompanyRepository: get Response', res)
        return res
    }
   
}

export default CompanyRepository;