export default {
  "headline": {
    "anybooka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anybooka"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "pw_forgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "pw_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "pw_reset_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Änderung erfolgreich"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "user_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich"])},
    "already_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast dies EMail-Adresse bereits bestätigt."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Moment bitte ..."])},
    "registration_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung abgeschlossen"])},
    "create_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Produkt anlegen"])},
    "edit_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt bearbeiten"])},
    "delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt löschen"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
    "product_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt Information"])},
    "product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt Kategorien"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
    "create_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Artikel anlegen"])},
    "edit_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel bearbeiten"])},
    "delete_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel löschen"])},
    "create_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Produktkategorie anlegen"])},
    "edit_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorie bearbeiten"])},
    "delete_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorie löschen"])},
    "create_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung erstellen"])},
    "edit_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bearbeiten"])},
    "create_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neuer Standort"])},
    "edit_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort bearbeiten"])},
    "delete_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort löschen"])},
    "create_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Saison"])},
    "edit_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison bearbeiten"])},
    "delete_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison löschen"])},
    "create_openinghour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Öffnungszeiten"])},
    "openinghours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
    "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
    "delete_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung löschen"])},
    "delete_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide löschen"])},
    "create_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide anlegen"])},
    "edit_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide bearbeiten"])},
    "create_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetrieb erstellen"])},
    "update_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetrieb bearbeiten"])},
    "delete_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partnerbetrieb löschen"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
    "cancel_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung stornieren"])},
    "booking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung"])},
      "select_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Abenteuer"])},
      "select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschtes Datum"])},
      "select_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschte Uhrzeit"])},
      "booking_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsaten"])},
      "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
      "booking_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
      "payment_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "select_payment_methode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle deine Zahlungsart"])},
      "payment": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungs fehlgeschlagen!"])},
        "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])}
      },
      "redeem_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein einlösen"])},
      "partner_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])}
    },
    "import_vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheine importieren"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung abbrechen"])}
  },
  "form": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "password_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "new_password_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "item_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Artikel"])}
  },
  "button": {
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "send_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link anfordern"])},
    "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück zum Login"])},
    "to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])},
    "reset_pw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neues Produkt"])},
    "add_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Kategorie"])},
    "add_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Artikel"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlegen"])},
    "add_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neuer Standort"])},
    "add_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Saison"])},
    "add_openinghour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Öffnungszeiten"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "save_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle speichern"])},
    "add_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neuer Guide"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "book_with_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenpflichtig buchen"])},
    "pay_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt bezahlen"])},
    "to_booking_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Bestellübersicht"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])},
    "add_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neuer Partner"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen"])},
    "add_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Buchung"])},
    "cancel_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
    "add_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein erstellen"])},
    "import_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheine importieren"])},
    "sync_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheine synchronisieren"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
    "redeem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einlösen"])}
  },
  "label": {
    "not_registered_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch nicht registriert?"])},
    "register_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt registrieren"])},
    "pw_forgotten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "accept_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Datenschutzbestimmungen gelesen und akzeptiere diese."])},
    "read_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen lesen"])},
    "accept_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Allgemeinen Geschäftsbedinungen gelesen und akzeptiere diese."])},
    "read_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeinen Geschäftsbedinungen lesen"])},
    "create_user_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerkonto erstellen"])},
    "item_number_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau Artikelnummer"])},
    "set_items_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel aktiv setzen"])},
    "openinghours_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Öffnungszeiten"])},
    "product_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktinformation"])},
    "product_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
    "product_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
    "all_seasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Saisonen"])},
    "all_locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Standorte"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "availability": {
      "option": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer verfügbar"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["während der Öffnungszeiten"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individuell"])}
      }
    },
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
    "scheduler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guides"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "select_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle das gewünschte Produkt"])},
    "booking": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uhrzeit"])},
      "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
      "timeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "priceSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelpreis"])},
      "priceTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
      "units_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["x ", _interpolate(_named("unit")), " min"])},
      "only_available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["nur mehr ", _interpolate(_named("amount")), " verfügbar"])},
      "customer": {
        "user": {
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plz"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "feUser": {
            "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
            "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])}
          }
        }
      },
      "payment": {
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsstatus"])},
        "checking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe Status der Zahlung"])}
      },
      "step": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abenteuer wählen"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum wählen"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uhrzeit wählen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdaten"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Informationen"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])}
      },
      "voucher_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheincode"])},
      "enter_voucher_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheincode eingeben"])},
      "price_to_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu bezahlender Gesamtpreis"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
      "amount_voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein"])},
      "price_payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Preis"])},
      "partner": {
        "partnerNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])}
      }
    },
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetriebe"])},
    "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheine"])},
    "voucher_import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importdatei"])}
  },
  "error": {
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erforderlich"])},
    "wrong_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ungültige EMail-Adresse"])},
    "too_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu kurz"])},
    "dont_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
    "privacy_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Datenschutzbestimmungen zustimmen"])},
    "terms_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte den Geschäftsbedinungen zustimmen"])},
    "booking": {
      "no_longer_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschter Zeitraum nicht mehr verfügbar!"])}
    }
  },
  "message": {
    "link_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben dir einen Link zum Zurücksetzen deines Passwortes geschickt. Bitte kontrolliere auch deinen Spam / Junk Ordner."])},
    "pw_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Passwort erfolgreich zurückgesetzt!"])},
    "already_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist bereits registriert?"])},
    "submit_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätige nun die E-Mail, die wir dir geschickt haben, um dein Profil zu aktivieren."])},
    "submit_mail_tipp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleiner Tipp: Bitte überprüfe auch deinen Spam / Junk Ordner."])},
    "redirect_to_login": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du wirst in ", _interpolate(_named("time")), " Sekunde/n automatisch zum Login weitergeleitet."])},
    "successfully_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Registrierung wurde erfolgreich abgeschlossen. Du kannst dich jetzt anmelden."])},
    "login_incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Anmeldung"])},
    "success": {
      "product_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Produkt wurde erfolgreich erstellt"])},
      "product_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde erfolgreich aktualisiert"])},
      "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt wurde erfolgreich gelöscht"])},
      "items_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Artikel wurden erfolgreich angelegt"])},
      "item_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich gelöscht"])},
      "item_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Artikel wurde erfolgreich aktualisiert"])},
      "product_category_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die neue Produktkategorie wurde erfolgreich erstellt"])},
      "product_category_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktkategorie wurde erfolgreich gelöscht"])},
      "product_category_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produktkategorie wurde erfolgreich aktualisiert"])},
      "location_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der neue Standort wurde erfolgreich erstellt"])},
      "location_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde erfolgreich aktualisiert"])},
      "location_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde erfolgreich gelöscht"])},
      "season_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die neue Saison wurde erfolgreich erstellt"])},
      "season_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Saison wurde erfolgreich aktualisiert"])},
      "season_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Saison wurde erfolgreich gelöscht"])},
      "openinghour_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten erfolgreich gelöscht"])},
      "availability_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit erfolgreich gelöscht"])},
      "booking_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung erstellt"])},
      "booking_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung aktualisiert"])},
      "booking_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung gelöscht"])},
      "booking_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung storniert"])},
      "guide_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide gelöscht"])},
      "guide_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide angelegt"])},
      "guide_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide aktualisiert"])},
      "price_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis gelöscht"])},
      "partner_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetrieb erstellt"])},
      "partner_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetrieb aktualisiert"])},
      "partner_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerbetrieb gelöscht"])}
    },
    "confirm_delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt unwiederbringlich löschen?"])},
    "confirm_delete_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel unwiederbringlich löschen?"])},
    "confirm_delete_productcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktkategorie unwiederbringlich löschen?"])},
    "confirm_delete_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort unwiederbringlich löschen?"])},
    "confirm_delete_season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison unwiederbringlich löschen?"])},
    "confirm_delete_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung unwiederbringlich löschen?"])},
    "confirm_delete_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide unwiederbringlich löschen?"])},
    "confirm_delete_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parnterbetrieb unwiederbringlich löschen?"])},
    "confirm_cancel_booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Buchung stornieren?"])},
    "booking": {
      "payment": {
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Bearbeitung deiner Zahlung ist ein Fehler aufgetreten. Fehlercode:"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Zahlung wurde erfolgreich durchgeführt."])}
      },
      "voucher": {
        "voucher_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheincode ungültig!"])},
        "voucher_fully_redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein bereits vollständig eingelöst!"])}
      }
    },
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du die Zahlung tatsächlich abbrechen und zur Startseite wechseln?"])}
  },
  "route": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
    "productcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "seasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisonen"])},
    "openinghours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
    "guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guides"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutscheine"])}
  },
  "placeholder": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen / Filtern"])}
  },
  "nodata": {
    "productCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Produkt Kategorien vorhanden"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Produkte gefunden"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Artikel gefunden"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte gefunden"])},
    "seasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Saisonen gefunden"])},
    "openinghours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Öffnungszeiten gefunden"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Buchungen gefunden"])},
    "guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Guides gefunden"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Partnerbetriebe gefunden"])},
    "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gutscheine gefunden"])}
  },
  "model": {
    "productCategory": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])}
    },
    "product": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "productNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "productCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
      "numberGuides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guides"])},
      "settings": {
        "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundfarbe in Kalender"])},
        "setupTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Zeit (min)"])},
        "bookingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min Buchungszeit (min)"])},
        "bookableEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buchbar alle (min)"])},
        "maxUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. buchbare Einheiten"])}
      }
    },
    "productavailability": {
      "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "fromTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
      "toTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    },
    "productprice": {
      "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])}
    },
    "item": {
      "itemNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muster"])},
      "productNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktnummer"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])}
    },
    "booking": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "periodFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
      "periodTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
      "periodFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum von"])},
      "periodDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "periodToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum bis"])},
      "periodFromTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit von"])},
      "periodToTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bis"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "priceTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
      "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
      "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestätigt"])},
      "confirmedByPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestätigt von Partner"])},
      "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])}
    },
    "season": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "seasonFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisonstart"])},
      "seasonToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisonende"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])}
    },
    "openinghour": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "fromTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
      "toTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison"])},
      "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    },
    "guide": {
      "guideCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "guideName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    },
    "partner": {
      "partnerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "user": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email (Benutzername)"])}
      }
    },
    "voucher": {
      "voucherCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "customer": {
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde Vorname"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde Nachname"])}
      },
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])},
      "redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingelöst"])},
      "ready2OrderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r2o"])}
    }
  },
  "scheduler": {
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendar"])}
  },
  "days": {
    "long": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
    },
    "short": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])}
    }
  }
}