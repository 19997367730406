import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const LocationRepository = {
    
    /**
     * getLocations
     * 
     * @returns {Object} server response
     */ 
    async getLocations() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_LOCATIONS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('LocationRepository: getLocations Response', res)
        return res
    },

    /**
     * getLocation
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getLocation(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_LOCATION + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('LocationRepository: getLocation Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_LOCATION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createLocation', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_LOCATION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateLocation', res)
        return res;
    },

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_LOCATION, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteLocation', res)
        return res;
    }
   
}

export default LocationRepository;