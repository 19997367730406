import router from "@/router";
import { UserController, CompanyController } from '@/controller'

const RedirectService = { 
  router,

    redirect() {
        console.log('redirect: this.settings', CompanyController.settings)
            //company data not loaded yet
        if (CompanyController.settings == null) {
            setTimeout(() => this.redirect(), 100)
            return
        }

        const usergroups = (UserController.user as any).feUser?.usergroup
        let usergroup
        for (const ugKey in usergroups) {
            usergroup = usergroups[ugKey]
        }
        if (usergroup) {
            if (usergroup.uid == CompanyController.settings.adminGroup) {
                this.router.push('/admin')
            } else if (usergroup.uid == CompanyController.settings.partnerGroup) {
                this.router.push('/partner')
            } else {
                this.router.push('/home')
            }
        }
    }

}

export default RedirectService;