import { PartnerRepository } from "../repository"
import { PostData } from "@/utils"
import { isProxy, toRaw } from 'vue'
import { BookingControllerService } from '@/service/controllerservice' 

const PartnerController = {

    /**
    * @return {Object} request data
    */
    async getPartners() {
        const res = await PartnerRepository.getPartners();
        return res?.data?.partners || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getPartner(uid: number) {
        const res = await PartnerRepository.getPartner(uid);
        return res?.data?.partner || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        /* if (isProxy(data)) {
            console.log('isProxy', data, toRaw(data))
            data = toRaw(data)
        } */
        const postData = JSON.stringify(data)
        const response : any = await PartnerRepository.create(postData)
        return response?.data?.partner || response?.error;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = JSON.stringify(data)
        const response : any = await PartnerRepository.update(postData)
        return response?.data?.partner || response?.error;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const postData = JSON.stringify({ uid: uid })
        const response : any = await PartnerRepository.delete(postData) //PartnerRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    /* async createBooking(data: any) {
        const postData = JSON.stringify(data)
        console.log('data / postData', data,  postData)
        const response : any = await PartnerRepository.createBooking(postData)
        console.log('PartnerController create resopnse', response)
        if (response?.data?.booking) return BookingControllerService.mapBookingData(response.data.booking)
        else return response
        //return response?.data?.booking || response;
    },  */   

    /**
    * @return {Object} request data
    */
    async getPartnerBookings() {
        const res = await PartnerRepository.getPartnerBookings();
        //console.log('PartnerController -> getPartnerBookings -> response', res)
        return res?.data?.bookingProducts || [];
    },    
}

export default PartnerController;