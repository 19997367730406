const PostData =  {

    build(data: Array<any>): string
    {
        let postData = '';
        for (const key in data) {
            //console.log('build', key, data, typeof data[key])
            let value = ''
            if (typeof data[key] === 'object') {
                value = this.build(data[key])
            } else {
                value = data[key]
            }
            
            postData += (postData ? `&` : '') + `${key}=` + encodeURIComponent(value)
        }
        return postData;
    },

    buildFormData(data: Array<any>, form: any, namespace: string) 
    {
        const formData = form || new FormData()
        for (const key in data) {
            const formKey = (namespace ? namespace + '[' + key + ']' : key) as string
            if (typeof data[key] === 'object') {
                this.buildFormData(data[key], formData, key)
            } else {
                formData.append(formKey, data[key])
            }
            
            //postData += (postData ? `&` : '') + `${key}=` + encodeURIComponent(value)
        }
        return formData;
    }

};
export default PostData;