import { SeasonRepository } from "../repository";
import { PostData } from "@/utils";

const SeasonController = {

    /**
    * @return {Object} request data
    */
    async getSeasons() {
        const res = await SeasonRepository.getSeasons();
        return res?.data?.seasons || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getSeason(uid: number) {
        const res = await SeasonRepository.getSeason(uid);
        return res?.data?.season || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await SeasonRepository.create(postData)
        return response?.data?.season || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await SeasonRepository.update(postData)
        return response?.data?.season || response;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await SeasonRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },
}

export default SeasonController;