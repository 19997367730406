import { UserController, CompanyController } from '@/controller'

const UserRightsService = { 

  usergroup: '',

  routes: [
    {
      'path': '/admin',
      'allowed': 'admin'
    },
    {
      'path': '/partner',
      'allowed': 'admin,partner'
    }
  ],

  /**
  * @return void
  */
  setUserRights() {
    console.log('setUserRights: ', UserController.user, CompanyController.settings)
    if (UserController.user && CompanyController.settings) {
      for (const groupKey in UserController.user.feUser.usergroup) {
          if (UserController.user.feUser.usergroup[groupKey].uid == CompanyController.settings.adminGroup) {
            this.usergroup = 'admin'
          } else if (UserController.user.feUser.usergroup[groupKey].uid == CompanyController.settings.partnerGroup) {
            this.usergroup = 'partner' 
          } else {
            this.usergroup = 'user'
          }
      }
      console.log('setUserRights: ', this.usergroup)
    }
  },

  /**
  * checkRouteAllowed 
  *
  * @param {string} path
  * @return {boolean} true if allowed
  */  
  checkRouteAllowed(path: string) {
    const routeEntry = this.routes.find((e: any) => path.indexOf(e.path) != -1)
    if (routeEntry) {
      const ugs = routeEntry.allowed.split(',')
      const usergroup = ugs.find((u: any) => u == this.usergroup)
      return usergroup != null
    } else {
      return true
    }
    //return routeEntry?.allowed == '' || routeEntry?.allowed == this.usergroup ? true : false
  } 

}

export default UserRightsService;