
import { defineComponent } from "vue";
import store from "@/store/index";
import { UiIconButton } from '@/ui';
import { Converter } from "@/utils";

export default defineComponent({
    name: "UiTable",
    components: { UiIconButton },
    props:{
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        fields: {
            type: Array as any,
            default: null
        },
        actions: {
            type: Array as any,
            default: null
        },
        defaultSortField: {
            type: String,
            default: ""
        },
        src: {
            type: Array as any,
            default: null
        },
        emptyStateText: {
            type: String,
            default: ""
        }
    },
    data(){
        return{
            store,
            currentSortField: "",
            sortedAsc: true,
            hover: "#",
            sortedList: [] as Array<any>,
            icons: {
                arrowsort: '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M6 6.59998V1.59998" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6 11.6V6.59998" stroke="#1B1B1B" stroke-miterlimit="10"/>  <path d="M10 7.59998L6 11.6" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M2 7.59998L6 11.6" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                toggle: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="14px" viewBox="0 0 18 14" enable-background="new 0 0 18 14" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="#1B1B1B" d="M13,11c2.2,0,4-1.8,4-4s-1.8-4-4-4H5C2.8,3,1,4.8,1,7s1.8,4,4,4H13z"/><path fill="#FFFFFF" d="M16,7c0,1.7-1.3,3-3,3l0,0c-1.7,0-3-1.3-3-3l0,0c0-1.7,1.3-3,3-3l0,0C14.7,4,16,5.3,16,7L16,7z"/></svg>'
            }
        }
    },
    mounted() {
        if(this.defaultSortField) {
            this.currentSortField = this.defaultSortField;
        } else {
            this.currentSortField = this.fields[0].property;
        }
        this.sortTable(this.currentSortField)
    },
    watch: {
        src() {
            this.sortTable(this.currentSortField)
        }
    },
    computed: {
        emptyState(){
            if(this.src.length == 0 ||  this.src == null){
                return true;
            } else {
                return false;
            }
        }
    },
    methods:{
        handleSortBy(field:any){
            if(field == this.currentSortField){
                this.sortedAsc = !this.sortedAsc
            } else {
                this.currentSortField = field;
            }
            this.sortTable(field) 
        },
        sortTable(field:any) {
            //console.log('table sort field: ', this.src);
            var sorted = [...this.src];

            if(field.type == "string" && this.sortedAsc) {
                sorted = sorted.sort(function (a :any, b:any) {
                    return (a[field.property]?a[field.property]:'zz').toLowerCase().localeCompare((b[field.property]?b[field.property]:'zz').toLowerCase());
                });
            } else if(field.type == "string" && !this.sortedAsc) {
                sorted = sorted.sort(function (a :any, b:any) {
                    return (b[field.property] ? b[field.property]:'zz').toLowerCase().localeCompare((a[field.property]?a[field.property]:'zz').toLowerCase());
                });
            }
            this.sortedList = sorted;
        }, 
        startHover(item:any) {
            this.hover = item;
        },
        endHover() {
            this.hover = "";
        },
        isItemSelected(item:any) {
            if (item && typeof item.state != "undefined") {
                return item.state.isSelected;
            } else if (item && typeof item.isSelected != "undefined") {
                return item.isSelected;
            }
        },
        checkTBField(index : any, item : any, field : any){
            let output = null;  
            for(const [key, value] of Object.entries(item)){
                if (field.property == key){
                    if (field.type == 'objects') {
                        if (typeof value === 'object') {
                            return Object.keys(value as object).length
                        }
                    } else {
                        output = value;
                    }
                } else if(field.property.includes(".")) {
                    if(item[field.property.split(".")[0]]){
                        if (item[field.property.split(".")[0]][field.property.split(".")[1]]) {
                            output = item[field.property.split(".")[0]][field.property.split(".")[1]];
                        } else {
                            if (typeof item[field.property.split(".")[0]] === 'object') {
                                for (const [subproperty, subvalue] of Object.entries(item[field.property.split(".")[0]])) {
                                    const obj:any = subvalue
                                    if (obj && obj[field.property] && obj[field.property.split(".")[1]]) {
                                        output = (output ? ' ' : '') + obj[field.property.split(".")[1]]
                                    }
                                } 
                                //output = 'ich habe ein object'
                            }
                        }
                    }
                }           
            } 
            if(field.convert && output) {
                if(field.convert == "convertSecondsToTime"){
                    output =  Converter.convertSecondsToTime(output)
                } else if (field.convert == "convertTimeStampToDate"){
                    output =  Converter.convertTimeStampToDate(output)
                } else if (field.convert == 'convertToDate') {
                    output = Converter.convertToDate(output)
                } /* else if (field.convert == 'extractDateOnly') {
                    output = Converter.extractDateOnly(output)
                } */
            }

            if (output) {
                return output;
            }

            return "-";
        },
        handleActionClicked(action:string, item:any ) {
            if(action){
                this.$emit("actionClick", action, item);
            }
        }
    }
})
