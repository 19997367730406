import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "ww-tabs" }
const _hoisted_2 = {
  key: 0,
  class: "tab-button-container"
}
const _hoisted_3 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideTabsHead)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              class: _normalizeClass(["tab-button", {'active': index == _ctx.activeTab}]),
              title: tab,
              onClick: ($event: any) => (_ctx.handleSetActiveTab(index))
            }, _toDisplayString(tab), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}