import { ProductpriceRepository } from "../repository";
import { PostData } from "@/utils";

const ProductpriceController = {

    /**
    * @params {object} params 
    * @return {Object} request data
    */
    async getPrices(params? : any) {
        params = PostData.build(params)
        const res = await ProductpriceRepository.getPrices(params);
        return res?.data?.prices || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getPrice(uid: number) {
        const res = await ProductpriceRepository.getPrice(uid);
        return res?.data?.price || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        //let postData: any

        /* if (data.entries) {
            const buildEntries = data.entries.map((e:any) => PostData.build(e) )
            postData = PostData.build(buildEntries)
        } else { */
            const postData = PostData.build(data);
        //}
        console.log('data / postData', data, postData)
        const response : any = await ProductpriceRepository.create(postData)
        return response?.data?.price || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ProductpriceRepository.update(postData)
        return response?.data?.price || response;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await ProductpriceRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },
}

export default ProductpriceController;