import { ProductavailabilityRepository } from "../repository";
import { PostData } from "@/utils";

const ProductavailabilityController = {

    /**
    * @params {object} params 
    * @return {Object} request data
    */
    async getAvailabilities(params? : any) {
        params = PostData.build(params)
        const res = await ProductavailabilityRepository.getAvailabilities(params);
        return res?.data?.availabilities || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getAvailability(uid: number) {
        const res = await ProductavailabilityRepository.getAvailability(uid);
        return res?.data?.availability || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        //let postData: any

        /* if (data.entries) {
            const buildEntries = data.entries.map((e:any) => PostData.build(e) )
            postData = PostData.build(buildEntries)
        } else { */
            const postData = PostData.build(data);
        //}
        console.log('data / postData', data, postData)
        const response : any = await ProductavailabilityRepository.create(postData)
        return response?.data?.availability || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ProductavailabilityRepository.update(postData)
        return response?.data?.availability || response;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await ProductavailabilityRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },
}

export default ProductavailabilityController;