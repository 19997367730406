import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const PaymentRepository = {

    /**
     * payments
     * 
     * @returns {Object} server response
     */  
    /* async payments(){
        try {
            const res = await axios.get(Api.BACKEND_URL + Api.PAYMENTS_ACTION );
            //console.log('PaymentRepository:payments:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:payments:error', e);
            return {};
        }
   }, */

    /**
     * preparePayment
     * 
     * @param {Object} postData
     *
     * @returns {Object} server response
     */  
    async preparePayment(postData : object) {
        const res = await axios.post(Api.BACKEND_URL + Api.PAYMENT_PREPARE_ACTION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('PaymentRepository:preparePayment', res)
        return res;
   },

    /**
     * paymentData
     * 
     * @returns {Object} server response
     */  
    async paymentData(){
        try {
            const res = await axios.get(Api.BACKEND_URL + Api.PAYMENT_DATA_ACTION );
            //console.log('PaymentRepository:paymentData:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:paymentData:error', e);
            return {};
        }
   },

    /**
     * checkPaymentStatus
     * 
     * @param {Object} postData
     *
     * @returns {Object} server response
     */  
    async checkPaymentStatus(postData : object){
        const res = await axios.post(Api.BACKEND_URL + Api.PAYMENT_CHECK_PAYMENT_ACTION, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('PaymentRepository:checkPaymentStatus', res)
        return res;

        /* try {
            const res = await axios.post(Api.BACKEND_URL + Api.PAYMENT_CHECK_PAYMENT_ACTION, postData );
            console.log('PaymentRepository:checkPaymentStatus:res', res);
            return res
        } catch (e){
            console.log('PaymentRepository:checkPaymentStatus:error', e);
            return {};
        } */
   },   

}

export default PaymentRepository;