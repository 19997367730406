export const Api = Object.freeze({
    ANYBOOKER_VERSION: "v0.1",
    //BACKEND_URL: "https://absrv.webweb.works/api/v1",
    //BACKEND_URL: "https://anybooka.webweb.works/backend/api/v1",
    BACKEND_URL: process.env.VUE_APP_BACKEND,
    
        //login
    GET_PID: "/pid",
    CHECK_LOGIN: "/checklogin",
    LOGIN: "/login",
    LOGOUT: "/login",

        //login
    GET_USER: "/user",
    CREATE_USER: "/user/create",
    CONFIRM_EMAIL: "/user/confirmemail",
    FORGOT_PASSWORD: "/user/forgotpassword",
    RESET_PASSWORD: "/user/resetpassword",

        //company
    GET_COMPANY: "/company",

        //BaseData
    GET_BASEDATA: "/basedata",

        //Locations
    GET_LOCATIONS: "/locations",
    GET_LOCATION: "/location",
    CREATE_LOCATION: "/location/create",
    UPDATE_LOCATION: "/location/update",
    DELETE_LOCATION: "/location/delete",

        //Seasons
    GET_SEASONS: "/seasons",
    GET_SEASON: "/season",
    CREATE_SEASON: "/season/create",
    UPDATE_SEASON: "/season/update",
    DELETE_SEASON: "/season/delete",

        //Openinghours
    GET_OPENINGHOURS: "/openinghours",
    GET_OPENINGHOUR: "/openinghour",
    CREATE_OPENINGHOUR: "/openinghour/create",
    UPDATE_OPENINGHOUR: "/openinghour/update",
    DELETE_OPENINGHOUR: "/openinghour/delete",

        //Products
    GET_PRODUCTCATEGORIES: "/productcategories",
    GET_PRODUCTCATEGORY: "/productcategory",
    CREATE_PRODUCTCATEGORY: "/productcategory/create",
    UPDATE_PRODUCTCATEGORY: "/productcategory/update",
    DELETE_PRODUCTCATEGORY: "/productcategory/delete",
    GET_PRODUCTS: "/products",
    GET_PRODUCT: "/product",
    CREATE_PRODUCT: "/product/create",
    UPDATE_PRODUCT: "/product/update",
    DELETE_PRODUCT: "/product/delete",
    GET_ITEMS: "/items",
    GET_ITEM: "/item",
    CREATE_ITEM: "/item/create",
    UPDATE_ITEM: "/item/update",
    DELETE_ITEM: "/item/delete",

        //Productavailabilities
    GET_PRODUCTAVAILABILITIES: "/productavailabilities",
    GET_PRODUCTAVAILABILITY: "/productavailabilitiy",
    CREATE_PRODUCTAVAILABILITY: "/productavailability/create",
    UPDATE_PRODUCTAVAILABILITY: "/productavailability/update",
    DELETE_PRODUCTAVAILABILITY: "/productavailability/delete",    

        //Productprices
    GET_PRODUCTPRICES: "/productprices",
    GET_PRODUCTPRICE: "/productprice",
    CREATE_PRODUCTPRICE: "/productprice/create",
    UPDATE_PRODUCTPRICE: "/productprice/update",
    DELETE_PRODUCTPRICE: "/productprice/delete", 

        //Booking
    GET_BOOKINGS: "/bookings",
    GET_BOOKING: "/booking",
    CREATE_BOOKING: "/booking/create",
    UPDATE_BOOKING: "/booking/update",
    DELETE_BOOKING: "/booking/delete",    

        //Guides
    GET_GUIDES: "/guides",
    GET_GUIDE: "/guide",
    CREATE_GUIDE: "/guide/create",
    UPDATE_GUIDE: "/guide/update",
    DELETE_GUIDE: "/guide/delete",    

        //Partners
    GET_PARTNERS: "/partners",
    GET_PARTNER: "/partner",
    CREATE_PARTNER: "/partner/create",
    UPDATE_PARTNER: "/partner/update",
    DELETE_PARTNER: "/partner/delete",     

        //Partner Bookings   
    PARTNER_BOOKINGS: "/partner/bookings",

        //Vouchers
    GET_VOUCHERS: "/vouchers",
    GET_VOUCHER: "/voucher",
    IMPORT_VOUCHERS: "/voucher/import",
    CREATE_VOUCHER: "/voucher/create",
    UPDATE_VOUCHER: "/voucher/update",
    DELETE_VOUCHER: "/voucher/delete",      
    CHECK_VOUCHER: "/voucher/check",
    SYNC_VOUCHERS: "/voucher/synchronize",

        //Ticketing -> Frontend
    GET_TICKETINGS: "/ticketings",
    CREATE_TICKETING: "/ticketing/create",
    GET_TICKETING: "/ticketing",

    //Payment
    PAYMENT_DATA_ACTION: '/payment/data',
    PAYMENT_PREPARE_ACTION: '/payment/prepare',
    PAYMENT_CHECK_PAYMENT_ACTION: '/payment/check',
    //PAYMENTS_ACTION: 'payment/?tx_wwalprelax_payment[action]=payments',    
})
