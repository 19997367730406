import router from "./router"
import { UserController, CompanyController } from "@/controller"
import { UserRightsService, RedirectService } from '@/service'

//import { setLoading, unsetLoading } from "@/utils/loading";

const allowedRoutes = ['/', '/forgotpassword', '/resetpassword', '/login', '/home', '/payment']

router.beforeEach(async (to, from, next) => {
    const allow = allowedRoutes.filter((route) => to.path == route || (route != '/' && to.path.indexOf(route) != -1) );
    //console.log('to, freeRouting', to.path, allow?.length);
    if(allow?.length || to?.meta?.noLoginCheck === true) {
        next()
      } else {
        console.log('no free routing', to.path, allow?.length);
        //setLoading()
        if(!UserController.user) {
            await UserController.getUser()
            await CompanyController.getCompany()
            UserRightsService.setUserRights()
        }
            //unsetLoading()
        const allowed = UserRightsService.checkRouteAllowed(to.path)
        if (allowed) next() 
        else RedirectService.redirect()
    }
})

