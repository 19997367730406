import { CompanyRepository } from "../repository";
import { UserRightsService } from '@/service'

const CompanyController = {

    company: null as any,
    settings: null as any,
    
    /**
     * @return {Object} request data
     */
    async getCompany() {
        const res : any = await CompanyRepository.getCompany()
        console.log('CompanyController get response', res)
        this.company = res?.data?.company?.company || null
        this.settings = res?.data?.company?.settings || null
        console.log('CompanyController company / settings ', this.company, this.settings)
        UserRightsService.setUserRights()
        return res?.data?.company || res
    }
}

export default CompanyController;