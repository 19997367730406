
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
   name: 'UiCheckbox',
   props: {
       label: {
           type: String,
           default: ""
       },
       value: {
           type: Boolean,
           default: false
       }
   },
   data() {
        return {
            icons: {
                check: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M16.5 4.40002L6.9 14L3.8 10.9" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            }
        }
   },
   setup(props, { emit }) {
       const modelValue = ref(false);

       onMounted(() => {
           modelValue.value = props.value;
       })

       watch(() => props.value, () => {
           modelValue.value = props.value;
       })

       return {
           modelValue
       }
   },
   methods: {
       toggleCheckboxState(){
           this.modelValue =! this.modelValue
           this.$emit('onChange', this.modelValue)
       }
   }
})
