import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const BaseDataRepository = {
    
    /**
     * get
     * 
     * @params {string} postData
     * @returns {Object} server response
     */ 
    async get(postData: string) {
        const res: any = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.GET_BASEDATA, postData).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('BaseDataRepository: get Response', res)
        return res
    }
   
}

export default BaseDataRepository;