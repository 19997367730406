import { BookingRepository } from "../repository";
import { PostData } from "@/utils";
import { BookingControllerService } from "@/service/controllerservice"

const BookingController = {

    lastFetchedBooking: {} as any,

    /**
    * @return {Object} request data
    */
    async getBookings() {
        const res = await BookingRepository.getBookings();
        return res?.data?.bookingProducts || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getBooking(uid: number) {
        const response = await BookingRepository.getBooking(uid);
        if (response?.data?.booking) {
            this.lastFetchedBooking = response.data.booking
            return BookingControllerService.mapBookingData(response.data.booking)
        } else return response   
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = JSON.stringify(data) //PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await BookingRepository.create(postData)
        console.log('BookingController create resopnse', response)
        if (response?.data?.booking) {
            this.lastFetchedBooking = response.data.booking
            return BookingControllerService.mapBookingData(response.data.booking)        
        } else return response
        //return response?.data?.booking || response
    }, 

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await BookingRepository.update(postData)
        return response?.data?.bookingProduct || response;
    },        

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await BookingRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },  
}
export default BookingController;