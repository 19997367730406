import { ItemRepository } from "../repository";
import { PostData } from "@/utils";

const ItemController = {

    /**
    * @return {Object} request data
    */
    async getItems() {
        const res = await ItemRepository.getItems();
        return res?.data?.items || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getItem(uid: number) {
        const res = await ItemRepository.getItem(uid);
        return res?.data?.item || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ItemRepository.create(postData)
        return response?.data?.items || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await ItemRepository.update(postData)
        return response?.data?.item || response;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await ItemRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },

    /**
    * @param {Object} product
    * @return (number) 
    */
    getItemNumberIncrementel(product: any): number {
        let next = 0
        for (const key in product.items) {
            next = product.items[key].uid > next ? product.items[key].uid : next
        }
        return next +1;
    }

}
export default ItemController;