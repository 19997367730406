
import { defineComponent } from "vue"

export default defineComponent({
    name: "UiColorPicker",
    props:{
        color: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ""
        }
    },
    data(){
        return{
            newColor: this.color
        }
    },
    computed: {
        pickerStyle() {
            return 'background-color: ' + this.newColor + ';'
        }
    },
    watch: {
        color(){
            //console.log('newColor', this.color)
            this.newColor = this.color
        }
    },
    methods: {
        styleChange(){
            this.$emit('onChange', this.newColor)
            //this.$emit('onEventOnChange', this.newColor )
        }
    }
})
