import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ProductpriceRepository = {
    
    /**
     * getPrices
     * 
     * @params {Object} params
     * @returns {Object} server response
     */ 
    async getPrices(params? : any) {
        console.log('getPrices', params)
        //if (params?.season || params?.location) {
            const res: any = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.GET_PRODUCTPRICES, params).catch((error) => {
                return AxiosErrorService.handleError(error)
            });
            console.log('ProductpricesRepository: getPrices Response', res)
            return res
        /*}  else {
            const res: any = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.GET_OPENINGHOURS, params).catch((error) => {
                return AxiosErrorService.handleError(error)
            });
            console.log('OpeninghoursRepository: getOpeninghours Response', res)
            return res
        } */
    },

    /**
     * getPrice
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getPrice(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PRODUCTPRICE + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ProductpriceRepository: getPrice Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PRODUCTPRICE, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createProductprice', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_PRODUCTPRICE, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateProductprice', res)
        return res;
    },

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_PRODUCTPRICE, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteProductprice', res)
        return res;
    }
   
}

export default ProductpriceRepository;