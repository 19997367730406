import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ProductCategoryRepository = {
    
    /**
     * getProductCategories
     * 
     * @returns {Object} server response
     */ 
    async getProductCategories() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PRODUCTCATEGORIES).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ProductCategoryRepository: getProductCategories Response', res)
        return res
    },

    /**
     * getProductCategory
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getProductCategory(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PRODUCTCATEGORY + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ProductRepository: getProductCategory Response', res)
        return res
    },   

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PRODUCTCATEGORY, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createProductCategory', res)
        return res;
    },      

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_PRODUCTCATEGORY, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateProductCategory', res)
        return res;
    },     

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_PRODUCTCATEGORY, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteProductCategory', res)
        return res;
    }    
   
}

export default ProductCategoryRepository;