import { LocationRepository } from "../repository";
import { PostData } from "@/utils";

const LocationController = {

    /**
    * @return {Object} request data
    */
    async getLocations() {
        const res = await LocationRepository.getLocations();
        return res?.data?.locations || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getLocation(uid: number) {
        const res = await LocationRepository.getLocation(uid);
        return res?.data?.location || res;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await LocationRepository.create(postData)
        return response?.data?.location || response;
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await LocationRepository.update(postData)
        return response?.data?.location || response;
    },

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    async delete(uid: number) {
        const response : any = await LocationRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },
}

export default LocationController;