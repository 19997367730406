import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const PartnerRepository = {
    
    /**
     * getPartners
     * 
     * @returns {Object} server response
     */ 
    async getPartners() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PARTNERS).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('PartnerRepository: getPartners Response', res)
        return res
    },

    /**
     * getPartner
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getPartner(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PARTNER + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('PartnerRepository: getPartner Response', res)
        return res
    }, 

    /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.CREATE_PARTNER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('createPartner', res)
        return res;
    },

    /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.UPDATE_PARTNER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('updatePartner', res)
        return res;
    },

     /**
     * delete
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async delete(postData: string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.DELETE_PARTNER, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        })
        console.log('deletePartner', res)
        return res;
    },

     /**
     * createBooking
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    /* async createBooking(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PARTNER_BOOKING, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createTicketing', res)
        return res;
    },  */      

    /**
     * getPartnerBookings
     * 
     * @returns {Object} server response
     */ 
    async getPartnerBookings() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.PARTNER_BOOKINGS).catch((error) => {
            return AxiosErrorService.handleError(error)
        })
        console.log('PartnerRepository: getPartners Response', res)
        return res
    },    
   
}

export default PartnerRepository;