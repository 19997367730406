import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ItemRepository = {
    
     /**
     * getProduct
     * 
     * @returns {Object} server response
     */ 
    async getItems() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_ITEMS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ItemRepository: getItems Response', res)
        return res
    },
   
     /**
     * getItem
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getItem(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_ITEM + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ItemRepository: getItem Response', res)
        return res
    },   

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_ITEM, postData).catch((error) => {
           return AxiosErrorService.handleError(error);
        });
        console.log('createItem', res)
        return res;
    },   

     /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_ITEM, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateItem', res)
        return res;
    }, 

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_ITEM, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteItem', res)
        return res;
    },             
}

export default ItemRepository;