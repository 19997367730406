import { DateTimeUtils } from '@/utils'
import { ProductController } from '@/controller'

const BookingControllerService = {

    timeline: {} as any,
    summarizedBookingData: [] as any,

    /**
     * createDayTimeLine
     *
     * @param {any} product
     * @param {number} timeLineGroup
     * @param {string} id
     */
    createDayTimeLine(product: any, timeLineGroup: number, id:string): any {
        if (this.timeline[id]) {
            return this.timeline[id]
        } else {
            const productMaxAvailable:number = ProductController.getProductMaxAvailabilityPerBookable(product)
            const dayTimeRange = this.getDayRange(product)
            const timeline = this.createRangedTimeline(dayTimeRange, timeLineGroup, productMaxAvailable)
            const bookingDay = this.getSummarizedBookingData(id)
            if (bookingDay) this.addBookingsToTimeline(timeline, bookingDay, timeLineGroup)
            this.timeline[id] = timeline
            console.log(this.timeline)
            return this.timeline[id]
        }
    },

    /**
     * resetDayTimeline
     *
     */
    resetDayTimeline() {
        this.timeline = {} as any
    },

    /**
     * getDayRange
     *
     * @param {any} product
     */
    getDayRange(product: any): any {
        const dayTimeRange = {
            from: 0,
            to: 0,
            step: 0
        }
        if (product && product.availability == 2 && product.availabilities.length) { //individual
            dayTimeRange.from = product.availabilities[0].fromTime
            dayTimeRange.to = (product.availabilities[product.availabilities.length-1].toTime ? product.availabilities[product.availabilities.length-1].toTime : product.availabilities[product.availabilities.length-1].fromTime + product.settings.bookingTime) 
            dayTimeRange.step = product.settings.bookableEvery != 0 ? product.settings.bookableEvery : 60
        }
        console.log('dayTimeRange', dayTimeRange)
        return dayTimeRange
    },    

    /**
     * createRangedTimeLine
     *
     * @param {any} dayTimeRange
     * @param {number} timeLineGroup
     * @param {number} productMaxAvailability
     */
    createRangedTimeline(dayTimeRange: any, timeLineGroup: number, productMaxAvailable: number) {
        let i = dayTimeRange.from as any
        const timeline = {} as any
        while (i < dayTimeRange.to) {
            const entry = {
                timestamp: i,
                timeLabel: DateTimeUtils.convertTimeStampToTime(i),
                timeGroupLabel: DateTimeUtils.convertTimeStampToTime(i).split(':')[0],
                times: {} as any
            } as any

            if (timeLineGroup) {
                const end = i + timeLineGroup;
                while (i < end) {
                    entry.times[i] = {
                        timestamp: i,
                        timeLabel: DateTimeUtils.convertTimeStampToTime(i),                            
                        available: productMaxAvailable,
                        bookings: [] as Array<any>
                    } as any
                    i=i+(60*dayTimeRange.step)
                }
            } else {
                entry.times[i] = {
                    timestamp: i,
                    available: productMaxAvailable,
                    bookings: [] as Array<any>
                } as any
                i=i+(60*dayTimeRange.step)
            }
            timeline[entry.timestamp] = entry            
        }
        
        return timeline
    },

    /**
     * addBookingsToTimeline
     *
     * @param {any} timeline
     * @param {any} bookingDay
     * @param {number} timeLineGroup
     */
    addBookingsToTimeline(timeline: any, bookingDay: any, timeLineGroup: number) {
        let firstLevel = -1
        let timeKey = 0 as any
        for (timeKey in bookingDay.times) {
            firstLevel = this.getTimelineFirstLevel(timeline, timeKey, timeLineGroup)

            if (timeline[firstLevel].times[timeKey]) {
                timeline[firstLevel].times[timeKey].bookings.push(bookingDay.times[timeKey])
                timeline[firstLevel].times[timeKey].available -= bookingDay.times[timeKey].amount
            }
        }
        
    },

    getTimelineFirstLevel(timeline:any, bookingTime: any, timeLineGroup: number) {
        let firstLevel = -1
        let timeKey = 0 as any
        for (timeKey in timeline) {
            if (firstLevel == -1 || bookingTime >= parseInt(timeKey)) {
                firstLevel = parseInt(timeKey)
            }
        }   
        return firstLevel
    },

    /**
     * getSummarizedBookingData
     *
     * @param {string} id
     */
    getSummarizedBookingData(id:string): any {
        const data = this.summarizedBookingData as any
        return data.find((entry: any) => entry.id == id) || null
    },

    /**
     * summarizeBookings
     *
     * @param {any} bookings
     * @param {any} product
     */
    summarizeBookings(bookings: any, product: any) {
        //const bookings = this.bookings as []
        this.summarizedBookingData = [] as any
        bookings.forEach((booking: any) => {
                //TODO: only one product at a time in calendar, if more we have to check against booking.product
            //const product:any = this.products?.find((p:any) => p.uid = this.selectedProduct.uid)
            const available = ProductController.getProductMaxAvailabilityPerBookable(product)
            if (booking.periodFromDate && booking.periodToDate) {
                const end = new Date(Date.parse(booking.periodToDate))
                const loop = new Date(Date.parse(booking.periodFromDate))
                while (loop <= end) {
                    this.createUpdateBookingDataEntry(this.summarizedBookingData, loop.getTime(), booking.periodFromTime, booking.units, booking.amount, product, available)
                    loop.setDate(loop.getDate()+1)
                }
            } else if (booking.periodFromDate) {
                this.createUpdateBookingDataEntry(this.summarizedBookingData, Date.parse(booking.periodFromDate), booking.periodFromTime, booking.units, booking.amount, product, available)
            } else if (booking.periodToTDate) {
                this.createUpdateBookingDataEntry(this.summarizedBookingData, Date.parse(booking.periodToDate), booking.periodFromTime, booking.units, booking.amount, product, available)
            }
        })
        return this.summarizedBookingData
    },

    createUpdateBookingDataEntry(summary: any, dateTimestamp:number, timeTimestamp: number, units: number, amount: number, product: any, max: number) {
            //create an entry for every unit
        for (let i = 0; i < units; i++) {
            const fromTime = (timeTimestamp + product.settings.bookableEvery * i * 60)
            //console.log('createUpdateBookingDataEntry', timeTimestamp, units, fromTime)
            const id = DateTimeUtils.formatDateForInput(new Date(dateTimestamp))
            let entry:any = summary.find((e:any) => e.id == id && e.product == product.uid );
            if (!entry) {    
                entry = {}
                entry.available = false
                entry.product = product.uid
                    //
                entry.date = dateTimestamp
                entry.id = DateTimeUtils.formatDateForInput(new Date(dateTimestamp))
                entry.backgroundColor = product.settings?.backgroundColor                    
                entry.times = {}
                summary.push(entry)
            }
            const timeEntry = entry.times[fromTime] ? entry.times[fromTime] : entry.times[fromTime] = {}
            //entry.date = dateTimestamp
            //entry.id = DateTimeUtils.formatDateForInput(new Date(dateTimestamp))
            //entry.backgroundColor = product.settings?.backgroundColor
            timeEntry.product = product.uid
            timeEntry.from = fromTime
            timeEntry.amount = (timeEntry.amount || 0) +amount
            timeEntry.available = max - timeEntry.amount
            timeEntry.timeLabel = DateTimeUtils.convertTimeStampToTime(fromTime)
            if (timeEntry.available) entry.available = true
        }
    },    

    /**
     * maps the result of a created object to the bookingform structure
     */
    mapBookingData(b:any) {
        let product = null
        for(const key in b.products) {
            product = b.products[key]
        }

        const data = {
            uid: b.uid,
            acceptPrivacy: b.customer?.user?.feUser?.anybookaPrivacy,
            acceptTerms: b.customer?.user?.feUser?.anybookaTerms,
            priceTotal: b.priceTotal,
            priceToPay: b.priceToPay,
            payed: b.payed,
            booking: {
                amount: product?.amount,
                units: product?.units,
                product: product?.product?.uid,
                periodFromDate: product?.periodFromDate?.split('T')[0],
                periodToDate: product?.periodToDate?.split('T')[0],
                periodFromTime: product?.periodFromTime,
                periodToTime: product?.periodToTime,
                price: product?.price,
                priceTotal: product?.priceTotal
            },
            customer: {
                uid: b.customer?.uid,
                user: {
                    uid: b.customer?.user?.uid,
                    feUser: {
                        uid: b.customer?.user?.feUser?.uid,
                        username: b.customer?.user?.feUser?.username
                    },
                    firstName: b.customer?.user?.firstName,
                    lastName: b.customer?.user?.lastName,
                    address: b.customer?.user?.address,
                    zip: b.customer?.user?.zip,
                    city: b.customer?.user?.city,
                    country: b.customer?.user?.country,
                    phone: b.customer?.user?.telephone,
                    email: b.customer?.user?.email
                }
            }

        }
        console.log('TicketingController mapped data: ', data)
        return data
    }    
}

export default BookingControllerService;