
import { defineComponent } from "vue";

export default defineComponent({
    name: "ComSidebar",
    data(){
        return {
            activatedSub: '',
            icons: {
                dashboard: '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M6.49999 1.10999H0.679993V6.92998H6.49999V1.10999Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M15.22 1.10999H9.40002V6.92998H15.22V1.10999Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6.49999 9.83997H0.679993V15.66H6.49999V9.83997Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M15.22 9.83997H9.40002V15.66H15.22V9.83997Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                catalog: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M13.5 3.90002C14 4.40002 14 5.20002 13.5 5.70002C13 6.20002 12.2 6.20002 11.7 5.70002C11.2 5.20002 11.2 4.40002 11.7 3.90002C12.2 3.40002 13 3.40002 13.5 3.90002Z" stroke="#1B1B1B" stroke-width="0.8639" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M13.5 3.9L16.3 1" stroke="#1B1B1B" stroke-width="0.8639" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M10.1 2.3L2.5 9.8L7.5 14.8L15 7.3C16.4 5.9 16.4 3.7 15 2.3C13.6 0.9 11.4 0.9 10.1 2.3Z" stroke="#1B1B1B" stroke-width="0.8639" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M7.60001 17L15.1 9.5" stroke="#1B1B1B" stroke-width="0.8639" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M2.60001 12L7.60001 17" stroke="#1B1B1B" stroke-width="0.8639" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                booking: '<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M3.5 4.09998V15.6L8.2 13.5L12.9 15.6V4.09998H3.5Z" stroke="#1B1B1B" stroke-width="0.717" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M11 1.70001H1.60001V13.2" stroke="#1B1B1B" stroke-width="0.717" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                company: '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M3.17999 5.58997H16.27L9.73 1.21997L3.17999 5.58997Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M9.72998 7.76996V12.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M6.81998 7.76996V12.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M15.55 7.76996V12.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M12.64 7.76996V12.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M3.17998 14.3099H16.27L17.73 17.2199H1.72998L3.17998 14.3099Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M3.90997 7.76996V12.13" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>',
                partner: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">  <path d="M8.72999 5.02998C9.93397 5.02998 10.91 4.05396 10.91 2.84998C10.91 1.646 9.93397 0.669983 8.72999 0.669983C7.52601 0.669983 6.54999 1.646 6.54999 2.84998C6.54999 4.05396 7.52601 5.02998 8.72999 5.02998Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M3.63999 10.85C4.84397 10.85 5.81999 9.87396 5.81999 8.66998C5.81999 7.466 4.84397 6.48999 3.63999 6.48999C2.43601 6.48999 1.45999 7.466 1.45999 8.66998C1.45999 9.87396 2.43601 10.85 3.63999 10.85Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M13.82 10.85C15.024 10.85 16 9.87396 16 8.66998C16 7.466 15.024 6.48999 13.82 6.48999C12.616 6.48999 11.64 7.466 11.64 8.66998C11.64 9.87396 12.616 10.85 13.82 10.85Z" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M11.64 8.66997C11.64 6.25997 10.34 5.02997 8.72998 5.02997C7.11998 5.02997 5.81998 6.24997 5.81998 8.66997" stroke="#1B1B1B" stroke-miterlimit="10"/>  <path d="M6.54998 15.22V14.49C6.54998 12.08 5.24998 10.85 3.63998 10.85C2.02998 10.85 0.72998 12.07 0.72998 14.49V15.22" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/>  <path d="M16.73 15.22V14.49C16.73 12.08 15.43 10.85 13.82 10.85C12.21 10.85 10.91 12.07 10.91 14.49V15.22" stroke="#1B1B1B" stroke-miterlimit="10" stroke-linecap="square"/></svg>'
            }
        }
    },
    computed: {
      activeRouteName() {
        return  this.$route?.meta?.menu || this.$route.name
      },
      activeParentRouteName() {
        console.log(this.$route?.meta?.parentMenu, this.$route.name)
        return this.$route?.meta?.parentMenu;
      }
    },
    methods: {
        openSubNav(sub: string) {
            this.activatedSub = sub
        },
    }
})
