const AxiosErrorService = { 

  handleError(error: any) {
    console.log('AxiosErrorService error:', error.response);
    if (error.response) {
        return {
            'error': {
                'data': {
                    'error': {
                        'status': error.response.status,
                        'data': error.response.data,
                        'message': error.response.status + ' ' + error.response.data.error.message
                    },
                }
            }
        };
    } else {
        return {
            'error': {
                'data': {
                    'error': {
                        'status': '500',
                        'data': 'Server Error',
                        'message': '500 Server Error'
                    },
                    
                }
            }
        };
    }
  }

}

export default AxiosErrorService;