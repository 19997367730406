import { BaseDataRepository } from "../repository";
import { PostData } from "@/utils";

const BaseDataController = {

    weekdays: [] as Array<any>,

    /**
    * @params {string} type
    * @return {Object} request data
    */
    async get(type: string) {
        if (type == 'weekdays' && this?.weekdays?.length) {
            return this.weekdays
        } else {
            const postData = `datatype=${type}`
            const res = await BaseDataRepository.get(postData)
            console.log('BaseDataController get response', res)
            this.weekdays = res?.data?.weekdays
            return this.weekdays || []
        }
    }
}

export default BaseDataController;