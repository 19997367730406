import { TicketingRepository } from "../repository"
import { PostData } from "@/utils"
import { BookingControllerService } from "@/service/controllerservice"

const TicketingController = {

    lastFetchedBooking: {} as any,

    /**
    * @return {Object} request data
    */
    async getTicketings() {
        const res = await TicketingRepository.getTicketings();
        return res?.data?.bookingProducts || [];
    },

    /**
    * 
    * @param {number} uid
    * @return {Object} request data
    */
    async getTicketing(uid: number): Promise<any> {
        const response = await TicketingRepository.getTicketing(uid);
        console.log('TicketingControllergetTicketing:response', response)
        if (response?.data?.booking) {
            this.lastFetchedBooking = response.data.booking
            return BookingControllerService.mapBookingData(response.data.booking)
        }    
        else return response
    },

    /**
    * @param {any} data
    * @return {Object} request data
    */
    async create(data: any) {
        const postData = JSON.stringify(data) //PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await TicketingRepository.create(postData)
        console.log('TicketingController create resopnse', response)
        if (response?.data?.booking) {
            this.lastFetchedBooking = response.data.booking
            return BookingControllerService.mapBookingData(response.data.booking)
        }
        else return response
        //return response?.data?.booking || response;
    },


    /**
    * @param {any} data
    * @return {Object} request data
    */
    /* async update(data: any) {
        const postData = PostData.build(data);
        console.log('data / postData', data,  postData)
        const response : any = await BookingRepository.update(postData)
        return response?.data?.bookingProduct || response;
    },  */       

    /**
    * @param {number} uid
    * @return {Object} request data
    */
    /* async delete(uid: number) {
        const response : any = await BookingRepository.delete(`uid=${uid}`)
        return response?.data?.result || response;
    },  */ 
}
export default TicketingController;