import axios from "axios";
import { Api } from "../api";
import { AxiosErrorService } from "@/service/";

const ProductRepository = {
    
     /**
     * getProducts
     * 
     * @returns {Object} server response
     */ 
    async getProducts() {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PRODUCTS).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ProductRepository: getProducts Response', res)
        return res
    },
   
     /**
     * getProduct
     * 
     * @param {number} uid
     * @returns {Object} server response
     */ 
    async getProduct(uid: number) {
        const res: any = await axios.create({ withCredentials: true }).get(Api.BACKEND_URL + Api.GET_PRODUCT + '/' + uid).catch((error) => {
            return AxiosErrorService.handleError(error)
        });
        console.log('ProductRepository: getProduct Response', res)
        return res
    },   

     /**
     * create
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async create(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.CREATE_PRODUCT, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('createProduct', res)
        return res;
    },   

     /**
     * update
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async update(postData: any) {
        const res = await axios.post(Api.BACKEND_URL + Api.UPDATE_PRODUCT, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('updateProduct', res)
        return res;
    }, 

     /**
     * delete
     * 
     * @param {string} uid
     * 
     * @returns {Object} server response
     */ 
    async delete(uid: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.DELETE_PRODUCT, uid).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('deleteProduct', res)
        return res;
    },             
}

export default ProductRepository;